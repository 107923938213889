<template>
    <div class="p-relative" v-if="dataSource">
        
        <Toolbar 
            :menu="dataSource.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <div class="white-panel-items">
            <div class="wp-row">
                <div class="info-panel-text info-panel-text-uppercase"><b>{{ $t("Роль") }} {{isNewRecord ? $t('Новая') : dataSource.Role.name}}</b></div>
            </div>
        </div>

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Права") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">

                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Описание_роли") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">
                                                {{ $t("Наименование") }} 
                                            </label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Role.name ? dataSource.Role.name : $t("не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Role.name"
                                                hide-details
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Права") }}</div>
                                    <div class="resol-tree-wrapper">
                                        <div class="resol-tree-header">
                                            <div style="width: 66%;padding-left:18px;">{{ $t("Наименование") }}</div>
                                            <div>{{ $t("Значение") }}</div>
                                        </div>
                                        <SecurityRulesTreeView
                                            :items="allrules"
                                            :values="ruleValues"
                                            :openAll="true"
                                            :viewMode="isViewMode"
                                            :role="true"
                                        />
                                    </div>    
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<style scoped>
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }
</style>

<script>
import Toolbar from '@/components/Toolbar.vue';
import i18n from '@/i18n';
//import { mapGetters } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
//import sys from '@/services/system';
import SecurityRulesTreeView from '@/components/ems/SecurityRulesTreeView.vue';

export default {
    name: "EMSRoleCard",
    components: {
        Toolbar,
        SecurityRulesTreeView
    },
    props: {
        id: { type: String, required: true }
    },
    computed: {
        ruleValues(){
            return this.dataSource.Role.rules;
        },
        isViewMode() {
            return this.dataSource?.FormId === 602021;
        },
        isNewRecord() {
            return this.dataSource.Role.id=='00000000-0000-0000-0000-000000000000';
        }
    },
    data() {
        return {
            tab: null,
            dataSource: null,
            allrules: [],
            editbutton: {Action:"Edit",Icon:"edit",Text: i18n.t("Редактировать"),ToolTip:i18n.t("Редактировать_карточку_роли")},
            savebutton: {Action:"Save",Icon:"save",Text: i18n.t("Сохранить"),ToolTip:i18n.t("Сохранить_изменения_в_карточке_роли")},
            saveandclosebutton: {Action:"SaveAndClose",Icon:"save_and_close",Text: i18n.t("Сохранить_и_закрыть"),ToolTip:i18n.t("Сохранить_изменения_в_карточке_роли_и_закрыть_карточку")},
        }
    },
    methods: {
        async getRole(options = { edit: false }) {
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url = `/api/ems/getrole?id=${this.id}&edit=${options.edit}`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            
            if (response) {
                if (options.edit) {
                    this.dataSource.Menu = [this.savebutton, this.saveandclosebutton];
                    this.dataSource.FormId = response.data.payload.FormId;
                }
                else {
                    this.dataSource = response.data.payload;
                    if (this.isNewRecord)
                        this.dataSource.Menu = [this.savebutton, this.saveandclosebutton];
                    else     
                        this.dataSource.Menu = [this.editbutton];
                }
            }
            
        },
        async saveRole() {
            let validateResult = this.$refs.form.validate();

            if (!validateResult) {
                this.$notify.alert(this.$t("Не_заполнены_обязательные_поля"));
                return;
            }

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: `/api/ems/saverole`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify(this.dataSource.Role) },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            return response;
        },
        async onToolbarClick(e, btn) {
            await this[btn.Action]();
        },
        //#region Действия (тулбар)
        async Save() {
            let response = await this.saveRole();

            if (response) {
                if (this.isNewRecord)
                    this.$router.replace({ name: 'EMSRoleCard', query: { id: response.data.payload.Data.Object }, params: { id: response.data.payload.Data.Object } }, await this.getRole({ edit: true }));
                else
                    await this.getRole({ edit: true });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async SaveAndClose() {
            let response = await this.saveRole();

            if (response) {
                if (this.isNewRecord)
                    this.$router.replace({ name: 'EMSRoleCard', query: { id: response.data.payload.Data.Object }, params: { id: response.data.payload.Data.Object } }, await this.getRole({ edit: false }));
                else 
                    await this.getRole({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async Edit() {
            await this.getRole({ edit: true });
        },
        //#endregion
        async getRuleList(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/getallsecurityrulesforrole',
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            
            if (response) {
                this.allrules = response.data.payload.data.object;
            }
        },
    },
    created() {
        (async() => {
            await this.getRuleList();
            await this.getRole();
            
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.validate();
        })();      
    },
    updated() {
        if(this.dataSource !== null) {
            this.$refs?.form?.validate();
        }  
    }
}
</script>