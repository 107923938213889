<template>
    <v-main>

        <Header />

        <div class="content-box">
            <router-view :key="this.$route.fullPath" />
        </div>

        <div class="version-info-in-page">{{ $t('Версия') }}: {{ getVersion() }}</div>

        <PersonModal ref="PersonModalRef"/>

        <SelectMembersDlg v-if="isSelectMembersVisible" />
        <SelectMembersNewDlg v-if="isSelectMembersNewVisible" />
        <ExecutersGroupDlg v-if="isExecutersGroupVisible" />
        <OrderCounterDlg v-if="isOrderCounterVisible" />
        <PDFViewDlg v-if="isPdfViewVisible" />
        <NotifyInactiveDlg v-if="isNotifyInactiveVisible" />
        <SelectEnterprisesDlg v-if="isSelectEnterprisesVisible" />

    </v-main>
</template>

<script>
import { httpAPI, errorHandler } from "@/api/httpAPI";
import Header from '@/components/Header.vue';
import PersonModal from '@/components/dialogs/PersonModal';
import { mapGetters } from 'vuex';

export default {
    components: { 
        Header,
        PersonModal,
        SelectMembersDlg: () => import('@/components/dialogs/SelectMembersDlg'),
        SelectMembersNewDlg: () => import('@/components/dialogs/SelectMembersNewDlg'),
        ExecutersGroupDlg: () => import('@/components/dialogs/ExecutersGroupDlg'),
        OrderCounterDlg: () => import('@/components/dialogs/OrderCounterDlg'),
        PDFViewDlg:() => import('@/components/dialogs/PDFViewDlg'),
        NotifyInactiveDlg: () => import('@/components/dialogs/NotifyInactiveDlg'),
        SelectEnterprisesDlg : () => import('@/components/dialogs/SelectEnterpriseDlg'),
    },
    data: () => ({
        urls: []
    }),
    computed:{
        ...mapGetters('dialogs/selectMembers', { isSelectMembersVisible : 'isVisible' }),
        ...mapGetters('dialogs/selectMembersNew', { isSelectMembersNewVisible : 'isVisible' }),
        ...mapGetters('dialogs/executersGroup', { isExecutersGroupVisible : 'isVisible' }),
        ...mapGetters('dialogs/orderCounter', { isOrderCounterVisible: 'isVisible' }),
        ...mapGetters('dialogs/pdfView', { isPdfViewVisible : 'isVisible' }),
        ...mapGetters('dialogs/notifyInactive', { isNotifyInactiveVisible: 'isVisible', isNotifyInProcess: 'isNotifyInProcess' }),
        ...mapGetters('dialogs/selectEnterprise', { isSelectEnterprisesVisible: 'isVisible' }),      
        ...mapGetters({ userRegion: 'getUserRegion' }),  
    },
    methods: {
        getVersion() {
            
            let version = process?.env?.VUE_APP_VERSION ?? "0.0.0.0.0000"

            if (process.env.VUE_APP_CONFIGURATION == 'C5')
                return `${version}/${this.userRegion}`;
            else
                return `${version}`;
        }
    },
    mounted() {
        this.$eventBus.$on('show-employee-information', async id => {
            let promise = httpAPI({ url: `/api/references/employee/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        this.$eventBus.$on('show-workplace-information', async id =>
        {
            let promise = httpAPI({ url: `/api/references/workplace/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        this.$eventBus.$on('show-enterprise-information', async () =>
        {
            console.log('show-enterprise-information event triggered!');
        });

        this.$eventBus.$on('open-document', async data =>
        {
            console.log('open-document', data);
        });

        this.$eventBus.$on('on-download-file', async ({ id, name, isDocumentCommon }) => {
            let type = this.dataSourceType === 'Meeting' ? 'meetingattachment' : 'attachment';
            let url = `api/download/${type}?id=${id}&name=${name}`;
            let reportRequst = httpAPI({ 
                url, 
                method: 'GET', 
                responseType: 'blob', 
                skipErrorHandler: true, 
                headers: { 'isCommon': isDocumentCommon, 'API_KEY': localStorage.getItem('Token') }, 
            });

            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`Загрузка файла ${name} завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name );
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };
            
            let asyncReportToastMessage = `Загрузка файла ${name}`;
            this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        });

        this.$connectionsHub.$on('on-inactive-detected', async () => {
            if (this.isNotifyInProcess == false) {
                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_IN_PROCESS', true);
                let { confirmed } = await this.$store.dispatch('dialogs/notifyInactive/open', { openedTime: Date.now() });

                if (confirmed)
                    await httpAPI({ url: `/api/auth/confirm`, method: 'GET' });
                else
                    await this.$store.dispatch('auth/fetchLogout');

                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_IN_PROCESS', false);
            }
            else
                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_REPEAT_RECIEVED', true);
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('show-employee-information');
        this.$eventBus.$off('show-workplace-information');
        this.$eventBus.$off('show-enterprise-information');
        this.$eventBus.$off('open-document');
        this.$connectionsHub.$off('on-inactive-detected');
    }
};
</script>