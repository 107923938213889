<template>

    <div class="div-table-rows-container">

        <div
            :class="[isActive ? 'resol-tree-active' : '', 'div-table-row']"
            @click.stop="onItemClick(item)"
            @dblclick.stop="onItemDblClick(item)"
        >
            <!-- Ячейка Наименование-->
            <div class="div-table-row-cell" :style="{ 'width': `calc(${percentwidthFirstcolumn}% - ${margin}px)`, 'margin-left': `${margin}px` }" >
                <div class="elipsis-text-wrap">
                    <span class="last-with-line" v-if="!isParent && margin != 0"></span>
                    <!--Toggle-->
                    <span v-if="isParent">
                        <i :class="[ isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']" @click.stop="toggle" @dblclick.stop="() => {}"></i>
                    </span>
                    <span v-if="!isParent" class="resol-tree-no-icon-area"></span>
                    <template>
                        {{item.name}}
                    </template>
                </div>
            </div>

            <!-- Ячейка Значение-->
            <div class="div-table-row-cell" style="width: 33%;">

                <div v-if="item.valuetype=='Boolean'">

                    <div v-if="viewMode || isInhereted" class="onlyReadData">
                        {{ userRuleValue.toLowerCase() === "true" ? $t("Да") : "" }}
                    </div>

                    <v-switch
                        v-else
                        :input-value="userRuleValue.toLowerCase() === 'true'"
                        @change="onRuleValueInput($event)"
                        inset
                        hide-details
                        class="cust-switch"
                    />

                </div>
                
                <div v-else-if="item.valuetype === 'CspContainerRule'">

                    <div
                        v-if="viewMode"
                        class="etw-text-container"
                        v-tooltip.top-start="userCSPRuleValue"
                    >
                        <span class="etw-text">
                            {{ userCSPRuleValue }}
                        </span>
                    </div>

                    <v-text-field
                        v-else
                        :value="userCSPRuleValue"
                        class="mr-4"
                        :readonly="true"
                        hide-details
                        required
                        outlined
                        dense
                        clearable
                        @click:clear="onRuleValueInput(null)"
                        :append-icon="'mdi-dots-horizontal'"
                        @click:append="onCspContainerRuleEdit"
                    >
                    </v-text-field>

                </div>

                <div v-else-if="item.source" class="elipsis-text-wrap">
                    <div v-if="viewMode || sourceError" class="etw-text-container" v-tooltip.top-start="userSourceRuleValue">
                        <span v-if="sourceError" class="etw-text">
                            {{ sourceError }}
                        </span>
                        <span v-else class="etw-text">
                            {{ userSourceRuleValue }}
                        </span>
                    </div>
                    <v-select
                        v-else
                        v-model="userSourceRuleValueId"
                        :items="sourcelist"
                        item-text="name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        append-icon="fas fa-chevron-down"
                        :menu-props="{ bottom: true, offsetY: true }"
                        clearable
                        @click:clear = "userSourceRuleValueId = null"
                    >
                    </v-select>

                </div>

                <div v-else-if="item.valuetype" class="elipsis-text-wrap">

                    <div v-if="viewMode" class="etw-text-container" v-tooltip.top-start="userRuleValue">
                        <span class="etw-text">
                            {{ userRuleValue }}
                        </span>
                    </div>

                    <v-text-field
                        v-else
                        class="mr-2"
                        :value="userRuleValue"
                        @input="onRuleValueInput($event)"
                        hide-details
                        outlined
                        dense
                    >
                    </v-text-field>

                </div>

            </div>
            <div v-if="!role" class="div-table-row-cell"  style="width: 33%;">
                <div v-if="item.valuetype && isInhereted" class="onlyReadData more-per-lab-wrap">
                    <v-chip
                        v-for="rule in inheretedRules"
                        :key="rule.ownerid"
                        class="person-label immutable-text"
                        small
                        label
                        style="cursor: default!important"
                    >
                        <div v-tooltip.left-center="rule.inheritedfrom">{{ rule.inheritedfrom }}</div>
                    </v-chip>
                </div>
            </div>
        </div>

        <!--Рекурсия компонента-->
        <template v-if="isParent && isOpen">

            <SecurityRuleItem
                v-show="isOpen"
                v-for="child in item.children"
                :key="child.id"
                :item="child"
                :values="values"
                :viewMode="viewMode"
                :role="role"
                :level="level + 1"
                :open-all="openAll"
                :active-id="activeId"
                :on-item-click="onItemClick"
                :on-item-dbl-click="onItemDblClick"
                :on-invoke-action="onInvokeAction"
            />

        </template>

    </div>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
export default {
    name: "SecurityRuleItem",
    props: {
        item: Object,
        level: {
            type: Number,
            default: 0
        },
        values: Array,
        openAll: Boolean,
        viewMode: Boolean,
        role: Boolean,
        activeId: Number,
        onItemClick: Function,
        onItemDblClick: Function,
        onInvokeAction: Function
    },
    data() {
        return {
            isOpen: false,
            inheritedFrom: [],
            CSPRuleEditTooltip: 'Нажмите для редактирования ЭЦП',
            sourcelist: [],
            sourceError: null
        }
    },
    async created() {
        this.isOpen = this.level === 0 ? true : this.openAll;
        this.sourcelist = [];
        if (this.item.source){
            this.sourceError = 'Загрузка...';
            let response = await httpAPI({
                url: `api/security/getrulesource`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                skipErrorHandler: true,
                data: { Content : this.item.source },
            });
            if (response){
                if (response.data.success){
                    this.sourceError = null;
                    if (Array.isArray(response.data.payload) && response.data.payload.length > 0){
                        if (this.item.id == 1302){
                            this.sourcelist = [{id: Math.floor(response.data.payload[0].id/1000) * 1000, name: "Вся_область" }];
                            this.sourcelist.push(...response.data.payload);
                        }
                        else
                            this.sourcelist = response.data.payload;
                    }
                    else
                        this.sourceError = `Пустой_справочника: ${this.item.source}`;
                }
                else
                    this.sourceError = response.data.message;
            }
            else
                this.sourceError = `Ошибка_запроса_справочника: ${this.item.source}`;
        }
    },
    computed: {
        percentwidthFirstcolumn(){
            return this.role ? 66 : 33;
        },
        isParent() {
            return this.item.children != null;
        },
        margin() {
            return this.isParent && this.level > 0 ?  (this.level * 30) : this.level * 30;
        },
        isActive() {
            return this.item.id == this.activeId;
        },
        personalRule(){
            return this.values.find(x => !x.inherited && x.ruleid === this.item.id);
        },
        inheretedRules(){
            return this.values.filter(x => x.inherited && x.ruleid === this.item.id);
        },
        inheretedRule(){
            return this.inheretedRules[0];
        },
        isInhereted(){
            return !this.personalRule && this.inheretedRules.length > 0;
        },
        userRuleValue() {
            if (this.personalRule && this.personalRule.value)
                return this.personalRule.value.toString();
            else if (this.inheretedRule && this.inheretedRule.value)                
                return this.inheretedRule.value.toString();
            else if (this.item.valuetype=='Boolean')
                return 'False';
            else     
                return '';
        },
        userCSPRuleValue(){
            let ret = '';
            if (this.item.valuetype === 'CspContainerRule' && this.userRuleValue){
                let csp = JSON.parse(this.userRuleValue);
                ret=`Профайл:${csp.container}; Уникальное имя:${csp.dn}`;
            }
            return ret;
        },
        userSourceRuleValue() {
            let val = this.userRuleValue;
            if (val){
                if (this.item.source){
                    let it = this.sourcelist.find(i=>i.id == val);
                    if (it)
                        return it.name;
                    else 
                        return null;
                }
                return val;
            }
            return null;
        },
        userSourceRuleValueId: {
            get: function() {
                return parseInt(this.userRuleValue);
            },
            set: function(newValue) {
                let userRule = this.values?.find(x => !x.inherited && x.ruleid === this.item.id);
                if (userRule){
                    userRule.value = newValue;
                }
                else 
                    this.values.push({permid: '00000000-0000-0000-0000-000000000000', ruleid: this.item.id, value: newValue, inherited: false });
            }
            
        }
    },
    methods: {
        onRuleValueInput(event) {
            let userRule = this.values?.find(x => !x.inherited && x.ruleid === this.item.id);
            
            if (userRule) {

                switch (this.item.valuetype) {
                    case 'Boolean':
                        if (event) {
                            userRule.value = event.toString();
                        }
                        else {
                            this.values.splice(this.values.indexOf(userRule), 1);
                        }
                        break;

                    case 'CspContainerRule':
                        if (event) {
                            userRule.value = event;
                        }
                        else {
                            this.values.splice(this.values.indexOf(userRule), 1);
                        }
                        break;
                    default:
                        console.log(`Неизвестный тип права [type: ${this.item.valuetype}]`);
                        break;
                }
            }
            else {
                this.values.push({permid: '00000000-0000-0000-0000-000000000000', ruleid: this.item.id, value: event, inherited: false });
                //console.log(`Значение права '${this.item.name}' [id: ${this.item.id}] модуля '${this.item.module}' добавлено и установлено в ${event} [type: ${this.item.valuetype}]`);
            }
        },
        async onCspContainerRuleEdit() {
            try
            {
                let cspRuleValue = await this.$store.dispatch("emsDialogs/edsSettings/open", { source: this.userRuleValue ? JSON.parse(this.userRuleValue) : null });
                this.onRuleValueInput(cspRuleValue);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        toggle() {
            if (this.isParent)
                this.isOpen = !this.isOpen;
        }
    },
    watch: {
        openAll(newValue) {
            this.isOpen = newValue;
        }
    },
}
</script>