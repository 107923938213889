<template>
    <div class="p-relative" v-if="dataSource">
        
        <Toolbar 
            :menu="menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <div class="white-panel-items">
            <div class="wp-row">
                <div class="info-panel-text info-panel-text-uppercase"><b>{{ $t("Сотрудник") }} {{isNewRecord ? $t("Новый") : concatedName}}</b></div>
            </div>
        </div>

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>
            <v-tab  v-if="!isInnerEmployee && dataSource.Employee.user.login">
                {{ $t("Права") }}
            </v-tab>
            <v-tab  v-if="!isInnerEmployee">
                {{ $t("Замещающие") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >

            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Блок Персональные сведения-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">

                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Персональные_сведения") }}</div>

                                    <!--ФИО, если карточка в режиме просмотра-->
                                    <template v-if="isViewMode">

                                        <v-row no-gutters>

                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">
                                                    {{ $t("Фио") }} 
                                                </label>
                                            </v-col>

                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ concatedName }}
                                                </div>
                                            </v-col>

                                        </v-row>

                                    </template>
                                    <!--ФИО, если карточка в режиме редактирования-->
                                    <template v-else>
                                        
                                        <!-- поле Фамилия -->
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">
                                                    {{ $t("Фамилия") }} 
                                                </label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="dataSource.Employee.first_name"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>

                                            </v-col>

                                        </v-row>

                                        <!-- поле Имя -->
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">
                                                    {{ $t("Имя") }} 
                                                </label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="8">
                                                <v-popover
                                                    trigger="click focus"
                                                    placement="right"
                                                    class="popover-field"
                                                    @show="searchPersons"
                                                >
                                                    <v-text-field
                                                        v-model="dataSource.Employee.last_name"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        :rules="requiredRule"
                                                        @focus="searchPersons"
                                                    >
                                                    </v-text-field>

                                                    <template v-if="findedPerson.length>0" slot="popover">
                                                        <ul class="ribbon-button-submenu">
                                                            <li 
                                                                @click.stop="onExistedPersonSelect"
                                                                v-close-popover
                                                            >
                                                                Показать
                                                            </li>
                                                        </ul>
                                                        <span class="ribbon-button-submenu-title">Найдено {{findedPerson.length}} подходящих персон</span>
                                                    </template>
                                                    <template v-else-if="dataSource.Employee.first_name"  slot="popover">
                                                        <span class="ribbon-button-submenu-title">Нет подходящих персон</span>
                                                    </template>

                                                </v-popover>                                                
                                            </v-col>

                                        </v-row>

                                        <!-- поле Отчество -->
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">
                                                    {{ $t("Отчество") }} 
                                                </label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="dataSource.Employee.middle_name"
                                                    hide-details
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>

                                        </v-row>

                                    </template>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Дата_рождения") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">

                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Employee.birth_date ? $moment(dataSource.Employee.birth_date).format('DD.MM.YYYY') : $t("не_указано") }}
                                            </div>

                                            <v-menu 
                                                v-else
                                                v-model="BirthDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="BirthDate | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                        clearable
                                                        @click:clear="dataSource.Employee.birth_date = null"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker 
                                                    v-model="BirthDate"
                                                    @input="BirthDateMenu = false"
                                                    color="teal"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        
                        <!--Блок фото -->
                        <v-col cols="12" sm="12" md="auto" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Фотография") }}</div>
                                    <div class="d-flex justify-center">
                                        <v-icon v-if="dataSource.Employee.photo.length == 0" class="cardNoPhotoIcon mr-0">fas fa-user-circle</v-icon>
                                        <v-img
                                            v-else
                                            contain
                                            max-height="168"
                                            max-width="163"
                                            :src="dataSource.Employee.photo"
                                        >
                                        </v-img>
                                    </div>
                                    <input id="fileUpload" v-on:change="onFileChange" type="file" accept=".jpg,.png,.gif,.bmp,.svg,.tif" hidden>
                                    <div v-if="!isViewMode" class="d-flex justify-center mt-2">
                                        <v-chip
                                            class="person-label immutable-text mr-1"
                                            small
                                            label
                                            outlined
                                            @click="chooseFiles"
                                            v-if="dataSource.Employee.photo.length == 0"
                                        >
                                            <i class="fas fa-plus dark-gray-color"></i>
                                            <div>{{ $t("Добавить_фото") }}</div>
                                        </v-chip>
                                        <v-chip
                                            v-else
                                            class="person-label immutable-text"
                                            small
                                            label
                                            outlined
                                            @click="deletePhoto"
                                        >
                                            <i class="fas fa-trash dark-gray-color"></i>
                                            <div>{{ $t("Удалить_фото") }}</div>
                                        </v-chip>
                                    </div>
                                    <!--<div v-else class="onlyReadData"></div>-->
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Блок безопасность -->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Рабочее_место") }}</div>
                                    <v-row v-if="!isInnerEmployee" class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Индекс") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">{{dataSource.Employee.workplace_index}}</div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Employee.workplace_index"
                                                hide-details
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Организация") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{dataSource.Employee.entname}}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Подразделение") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{dataSource.Employee.depname}}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="!isInnerEmployee" class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Должность") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">{{dataSource.Employee.jobtitle_name}}</div>
                                            <v-autocomplete
                                                v-else
                                                :value="dataSource.Employee.jobtitle_id"
                                                @input="setJobTitle"
                                                :items="positions"
                                                item-text="name"
                                                item-value="id"
                                                return-object
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Блок Безопасность -->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper"
                            v-if="dataSource.Employee.user && dataSource.Employee.user.login"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Безопасность") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Логин") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group">
                                                <div v-if="dataSource.Employee.user && dataSource.Employee.user.locked">
                                                    <v-icon 
                                                        small 
                                                        v-tooltip.top-center='$t("Учетная_запись_заблокирована")'
                                                        class="icon-prefix"
                                                    >
                                                        fas fa-lock
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData">{{ dataSource.Employee.user.login }}</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Уровень_доступа") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ levelAccessValue }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Блок контакты -->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контакты") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Контакты") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div class="onlyReadData more-per-lab-wrap">
                                                <template v-if="dataSource.Employee.person_contacts.length>0">
                                                    <v-contact-item
                                                        v-for="contact in dataSource.Employee.person_contacts"
                                                        :key="contact.id"
                                                        :type="contact.type"
                                                        :icon="contact.icon"
                                                        :title="contact.value"
                                                        :description="contact.description"
                                                        :hide-load="true"
                                                        :is-read-only="isViewMode"
                                                        @editcontact="editContact" 
                                                        @deletecontact="deleteContact"
                                                    />
                                                </template>
                                                <template  v-else-if="isViewMode">
                                                    {{$t("Не_указано")}}
                                                </template>
                                                <template>
                                                    <v-chip
                                                        class="person-label immutable-text"
                                                        small
                                                        label
                                                        outlined
                                                        v-if="!isViewMode"
                                                        @click="onAddContact()"
                                                    >
                                                        <i class="fas fa-plus dark-gray-color"></i>
                                                        <div>{{ $t("Добавить_контакт") }}</div>
                                                    </v-chip>
                                                </template>
                                            </div>
                                        </v-col>
                                    </v-row>                                            
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-tab-item>

            <v-tab-item v-if="!isInnerEmployee && dataSource.Employee.user.login">
                <v-row dense>

                    <v-col cols="12" sm="12" md="12" class="section-wrapper">
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Роли_доступа") }}</div>
                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{ $t("Роли_доступа") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData more-per-lab-wrap">
                                            <template v-if="dataSource.Employee.user.roles.length>0">
                                                <RoleItem
                                                    v-for="user_role in dataSource.Employee.user.roles"
                                                    :key="user_role.id"
                                                    :title="user_role.name"
                                                    :hide-load="true"
                                                    :is-read-only="isViewMode"
                                                    @deleterole="deleteRole"
                                                />
                                            </template>
                                            <template v-else-if="isViewMode">
                                                {{$t("Не_указано")}} 
                                            </template>
                                            <template>
                                                <v-chip
                                                    class="person-label immutable-text"
                                                    small
                                                    label
                                                    outlined
                                                    v-if="!isViewMode"
                                                    @click="onAddRole()"
                                                >
                                                    <i class="fas fa-plus dark-gray-color"></i>
                                                    <div>{{ $t("Добавить_роль") }}</div>
                                                </v-chip>
                                            </template>
                                        </div>
                                    </v-col>
                                </v-row>     
                            </v-card-text>
                        </v-card>
                    </v-col>


                    <v-col cols="12" sm="12" md="12" class="section-wrapper">
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title title-with-right-part">
                                    <span>{{ $t("Права_доступа") }}</span>
                                    <div class="twrp-content">
                                        <div class="twrp-action" @click="toggleRulesTree">
                                            <v-icon role="button" small left>
                                                {{ rulesTreeOpenAll ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                                            </v-icon>
                                        </div>
                                   </div>
                                </div>
                                <div class="resol-tree-wrapper">
                                    <div class="resol-tree-header">
                                        <div style="width: 33%;padding-left:18px;">{{ $t("Наименование") }}</div>
                                        <div style="width: 33%;">{{ $t("Значение") }}</div>
                                        <div>{{ $t("Унаследовано") }}</div>
                                    </div>
                                    <SecurityRulesTreeView
                                        :items="allrules"
                                        :values="ruleValues"
                                        :openAll="rulesTreeOpenAll"
                                        :viewMode="isViewMode"
                                        :role="false"
                                    />
                                </div>

                                <EDSSettingsDlg />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-tab-item>

            <v-tab-item v-if="!isInnerEmployee">

                <v-row dense>
                    <v-col cols="12" sm="12" md="12" class="section-wrapper">
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title">{{ $t("Замещающие") }}</div>
                                <v-row no-gutters>

                                    <v-col cols="12" xs="12" md="12">
                                        <v-data-table
                                            :headers="substantials_headers"
                                            :items="substantials"
                                            :loading="loading"
                                            :options.sync="substantials_options"
                                            :server-items-length="substantials_total"
                                            :items-per-page="10"
                                            :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                                            :page.sync="substantials_options.page"
                                            @page-count="paginationSubstantialsPageCount = $event"
                                            class="elevation-1 customization-list-table pagination-list-table sticky-table"
                                        >
                                            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
                                            
                                            <template v-slot:[`item.fromdate`]="{ item }">
                                                {{item.fromdate | formattedDate}}
                                            </template>

                                            <template v-slot:[`item.todate`]="{ item }">
                                                {{item.todate | formattedDate}}
                                            </template>

                                            <template v-slot:[`item.fio`]="{ item }">
                                                <v-workplace-chip
                                                    :id="item.workplaceid"
                                                    :name="item.fio"
                                                >
                                                </v-workplace-chip>
                                            </template>
                                            
                                            <template v-slot:[`item.setemployeename`]="{ item }">
                                                <v-employee-chip
                                                    v-if="item.setemployeeid"
                                                    :id="item.setemployeeid"
                                                    :name="item.setemployeename"
                                                >
                                                </v-employee-chip>
                                            </template>

                                            <template v-slot:[`item.rec_date`]="{ item }">
                                                {{item.rec_date | formattedDate}}
                                            </template>

                                            <template v-slot:[`item.actions`]="{ item }">
                                                <div class="resol-tree-action-col" v-on:click.stop.prevent>
                                                    <v-popover
                                                        v-if="item.actions.length > 0"
                                                        trigger="click"
                                                    >
                                                        <v-chip
                                                            class="person-label"
                                                            small
                                                            label
                                                            color="orange lighten-3"
                                                        >
                                                            <v-icon color="#494F59">fas fa-ellipsis-v</v-icon>
                                                        </v-chip>

                                                        <template slot="popover">
                                                            <ul class="ribbon-button-submenu">
                                                                <li 
                                                                    v-for="action in item.actions"
                                                                    :key="action.action"
                                                                    @click="onInvokeSubstantialAction(action, item)"
                                                                    v-close-popover
                                                                >
                                                                    <v-icon left>
                                                                        {{ action.Icon }}
                                                                    </v-icon>

                                                                    {{ action.Text }}
                                                                </li>
                                                            </ul>
                                                            <span class="ribbon-button-submenu-title">{{ $t("Подменю_действий") }}</span>
                                                        </template>
                                                    </v-popover>
                                                </div>
                                            </template>

                                            <template v-slot:[`footer.prepend`]>
                                                <v-pagination
                                                    v-model="substantials_options.page"
                                                    :length="paginationSubstantialsPageCount"
                                                    :total-visible="$listPaginationTotal"
                                                    color="teal"
                                                ></v-pagination>
                                            </template>

                                        </v-data-table>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-tab-item>    
        </v-tabs-items>

        <ContactsDlg ref="ContactsRef" @on-save-contact="saveContact" />
        
        <SetSubstanteinDlg ref="SetSubstanteinDlgRef" />

        <CreateUserDlg ref="CreateUserDlgRef" />

        <ShowNewPassDlg ref="ShowNewPassDlgRef" />

        <ChangeLevelUserDlg ref="ChangeLevelUserDlgRef" />
    </div>
</template>

<style scoped>
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }
</style>

<script>
import i18n from '@/i18n';
import Axios from 'axios';
import Toolbar from '@/components/Toolbar.vue';
import { mapGetters, mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
import ContactsDlg from '@/components/dialogs/ContactsDlg';
import sys from '@/services/system';
import SecurityRulesTreeView from '@/components/ems/SecurityRulesTreeView.vue';
import RoleItem from '@/components/ems/v-role-item.vue';
import EDSSettingsDlg from '@/components/ems/dialogs/EDSSettingsDlg.vue';
import SetSubstanteinDlg from '@/components/ems/dialogs/SetSubstanteinDlg.vue';
import VEmployeeChip from '../../../../components/contractor-chips/v-employee-chip.vue';
import CreateUserDlg from '@/components/ems/dialogs/CreateUserDlg.vue';
import ShowNewPassDlg from '@/components/ems/dialogs/ShowNewPassDlg.vue';
import ChangeLevelUserDlg from '@/components/ems/dialogs/ChangeLevelUserDlg.vue';

export default {
    name: "EMSEmployeeCard",
    components: {
        Toolbar,
        ContactsDlg,
        SecurityRulesTreeView,
        RoleItem,
        EDSSettingsDlg,
        SetSubstanteinDlg,
        VEmployeeChip,
        CreateUserDlg,
        ShowNewPassDlg,
        ChangeLevelUserDlg
    },
    props: {
        id: { type: String, required: true },
        newinner: {type: Boolean, default: false}
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            iinRule: 'getIinRule',
            integerRule: 'getIntegerRule'
        }),
        ...mapGetters('auth', ['getUserInfo']),
        ...mapGetters('references', ['GetSecurityLevelAccess']),
        ruleValues(){
            return this.dataSource.Employee.user.rules;
        },
        isViewMode() {
            return this.dataSource?.FormId === 1302021;
        },
        isNewRecord() {
            return this.dataSource.Employee.employee_id=='00000000-0000-0000-0000-000000000000' //this.id === 'new';
        },
        isInnerEmployee(){
            return (this.dataSource.Employee.user.level_access && this.dataSource.Employee.user.level_access>0) || (this.licenseent && this.dataSource.Employee.enterprise_id==this.licenseent.id);
        },
        BirthDate: {
            get: function() {
                return this.dataSource.Employee.birth_date ? this.$moment(this.dataSource.Employee.birth_date).format('YYYY-MM-DD') : null;
            },
            set: function(newValue) {
                this.dataSource.Employee.birth_date = `/Date(${Date.parse(newValue)})/`;
            }
        },
        concatedName() {
            let result = this.$t("Не_указано");

            if (this.dataSource?.Employee?.first_name)
                result = this.dataSource.Employee.first_name;

            if (this.dataSource?.Employee?.last_name)
                result += ` ${this.dataSource.Employee.last_name}`;

            if (this.dataSource?.Employee?.middle_name)
                result += ` ${this.dataSource.Employee.middle_name}`;

            return result;
        },
        substantials_filter () {
            return {
                ...this.substantials_options,
            }
        },
        /*innerEmployeeJobTitle() {
            let result = '';
            if (this.dataSource.Employee.user.login){
                let level = this.GetSecurityLevelAccess.find(item=>item.id==this.dataSource.Employee.user.level_access);
                if (level)
                    result=level.Value;
            }
            return result;
        },*/
        levelAccessValue(){
            return this.GetSecurityLevelAccess.find(item=>item.id == this.dataSource.Employee.user.level_access)?.Value;
        }
    },
    data() {
        return {
            tab: null,
            dataSource: null,
            BirthDateMenu: false,
            findedPerson:[],
            allrules: [],
            allroles: [],
            isOpentooltipName: true,
            //disabledfield: [],
            rulesTreeOpenAll: true,
            positions: [],
            loading: true,
            substantials: [],
            substantials_headers: [
                { text: i18n.t("С_даты"), value: 'fromdate', width: '110px', sortable: false },
                { text: i18n.t("По_дату"), value: 'todate', width: '110px', sortable: false },
                { text: i18n.t("ФИО_замещающего"), value: 'fio', sortable: false },
                { text: i18n.t("Сведения_внес"), value: 'setemployeename', width: '30%', sortable: false },
                { text: i18n.t("Дата_изменений"), value: 'rec_date', width: '150px', sortable: false },
                { text: '', value: 'actions', width: '30px', sortable: false },
            ],
            substantials_options: {
                page: 1,
                itemsPerPage : 10,
                sortBy : [],
                sortDesc : [],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : false
            },
            substantials_total: 0,            
            paginationSubstantialsPageCount: 0,
            menu:[],
            licenseent:null
        }
    },
    methods: {
        ...mapActions('references', ['getLicenseEnterprise']),
        toggleRulesTree(){
            this.rulesTreeOpenAll=!this.rulesTreeOpenAll;
        },
        updateMenu(){
            let tmp_menu = [];
            this.dataSource.Menu.forEach(item => {
                switch(item.Action){
                    case "Edit":
                        if(!this.isInnerEmployee || this.getUserInfo.levelAccess>=50)
                            tmp_menu.push(item);
                        break;
                    case "SetSubstantein":
                        if (!this.isInnerEmployee)
                            tmp_menu.push(item);
                        break;
                    case "Remove":
                        if (!this.isInnerEmployee && this.getUserInfo.levelAccess<50)
                            tmp_menu.push(item);
                        break;
                    case "CreateLogin":
                        if (!this.dataSource.Employee.user.login && this.getUserInfo.levelAccess>=50){
                            tmp_menu.push(item);
                        }
                        break;
                    case "ChangeLevelAccess":
                        if (this.isInnerEmployee && this.dataSource.Employee.user.login && this.getUserInfo.levelAccess>=50){
                            tmp_menu.push(item);
                        }
                        break;
                    case "ResetPassword":
                        if (this.dataSource.Employee.user.login && this.getUserInfo.levelAccess>=50){
                            tmp_menu.push(item);
                        }
                        break;
                    case "Lock":
                    case "Unlock":
                        if (this.dataSource.Employee.user.login && (!this.isInnerEmployee || this.getUserInfo.levelAccess>=50))
                            tmp_menu.push(item);
                        break;
                    default:
                        tmp_menu.push(item);
                        break;
                }
            });
            this.menu = tmp_menu;
        },
        async getEmployee(options = { edit: false }) {
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            this.getPositionslist();
            let url = `/api/ems/getemployee?id=${this.id}&edit=${options.edit}`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) {
                this.dataSource = response.data.payload;
                //this.isUserWithoutLogin = !this.dataSource.Employee.user || !this.dataSource.Employee.user.login
                if (/*!this.isUserWithoutLogin &&*/ this.dataSource.Employee.workplace_id == this.getUserInfo.workplaceId)
                    this.dataSource.Menu = [];
                await this.getSubstantialsList();
            }
            this.updateMenu();
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        async saveEmployee() {
            let validateResult = this.$refs.form.validate();
            if (!validateResult) {
                this.$notify.alert(this.$t("Не_заполнены_обязательные_поля"));
                return;
            }

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url=`/api/ems/saveemployee`;
            if (this.dataSource.Employee.birth_date){
                this.dataSource.Employee.birth_date = `/Date(${Date.parse(this.BirthDate)})/`;
            }
            let senddata = { Content : JSON.stringify(this.dataSource.Employee) };
            
            if (!this.dataSource.Employee.photochanged && this.dataSource.Employee.photo){
                let sendObj = JSON.parse(senddata.Content);
                sendObj.photo = '';
                senddata.Content = JSON.stringify(sendObj);
            }

            let response = await httpAPI({
                url: url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: senddata,
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            return response;
        },
        async onToolbarClick(e, btn) {
            await this[btn.Action]();
        },
        async getPositionslist(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let filter = JSON.stringify({ SearchStr: "", OrderBy: 3, OrderDir: false });
            let response = await httpAPI({
                url: `api/ems/jobtitleslist?filter=${filter}`,
                method: 'GET',
            });
            this.positions = response?.data?.payload?.data ?? [];
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        async setJobTitle(position){
            if (position){
                this.dataSource.Employee.jobtitle_id = position.id;
                this.dataSource.Employee.jobtitle_name = position.name;
            }
        },

        //#region Работа с фото
        async getCurrentPersonPhoto() {
            let response = await httpAPI({
                url: `/api/ems/getpersonphoto?id=`+this.dataSource.Employee.person_id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            if (response)
                this.dataSource.Employee.photo=response?.data?.payload?.Data?.Object ?? '';
        },
        chooseFiles: function() {
            document.getElementById("fileUpload").click();
        },
        deletePhoto(){
            this.dataSource.Employee.photo = '';
            this.dataSource.Employee.photochanged = true;
        },
        onFileChange(e){
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;
                
            var reader = new FileReader();
            reader.readAsDataURL(files[0])
            reader.onload = () => {
                this.dataSource.Employee.photo = reader.result;
                this.dataSource.Employee.photochanged = true;
            };
        },
        //#endregion

        //#region выбор персоны
        async searchPersons() {
            if (this.dataSource.Employee.first_name){
                let response = await httpAPI({
                    url: `/api/ems/searchpersonwithcheck`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({wpid:this.dataSource.Employee.workplace_id, firstName:this.dataSource.Employee.first_name}) },
                });
                this.findedPerson = response?.data?.payload ?? [];
            }
            else 
                this.findedPerson = [];
        },
        async onExistedPersonSelect() {
            let selectMemberParams =  {
                title: this.$t("Выбор_персоны"),
                fromSource: true,
                source: this.findedPerson.map( x =>
                    [ 
                        x.person_id, //по идее, тут должен быть workplace, но можно использовать и person_id в качестве идентификатора
                        `${x.first_name} ${x.last_name} ${x.middle_name}`, //формируем для отображение полное имя, формат Фамилия И.О. тут не подходит
                        null, //employeeId нас не интересует
                        `${x.birth_date ? ` ${this.$t("Дата_рождения")}: ${this.$moment(x.birth_date).format('DD.MM.YYYY')}` : ""}`//подробности для персоны, дата рождения, т.к. поля присутствуют на форме карточки
                    ]),
                multiple: false,
                selected: []
            }

            try
            {
                let selectedPerson = await this.$store.dispatch('dialogs/selectMembers/open', selectMemberParams);
                this.selectPerson(this.findedPerson.find( x => x.person_id === selectedPerson.workplaceId));
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async selectPerson(person) {
            if (person){
                if (person.check){
                    //let checkinfo = JSON.parse(person.check);
                    //this.$notify.alert(this.$t('Персона_уже_работает_в') +' '+ checkinfo.entname);
                    this.$notify.alert(person.check);
                    return;
                }
                else 
                    this.setPersonFromSelect(person)
            }
        },
        async setPersonFromSelect(person){
            if (person){
                this.dataSource.Employee.person_id = person.person_id;
                this.dataSource.Employee.first_name = person.first_name;
                this.dataSource.Employee.last_name = person.last_name;
                this.dataSource.Employee.middle_name = person.middle_name;
                this.dataSource.Employee.birth_date = person.birth_date;
                this.dataSource.Employee.person_contacts = person.person_contacts;
                //this.checkDisabledFields();
                this.findedPerson = [];
                this.getCurrentPersonPhoto();
            }
        },
        //#endregion

        //#region Действия (тулбар)
        async Save() {
            let response = await this.saveEmployee();

            if (response) {
                this.id=response.data.payload.Data.Object;
                await this.getEmployee({ edit: true });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async SaveAndClose() {
            let response = await this.saveEmployee();
            if (response) {
                this.id=response.data.payload.Data.Object;
                await this.getEmployee({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async Lock(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/lockuser?id='+this.dataSource.Employee.user.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                await this.getEmployee({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async Unlock(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/unlockuser?id='+this.dataSource.Employee.user.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                await this.getEmployee({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async Edit() {
            await this.getEmployee({ edit: true });
        },
        async Remove(){
            this.$notify.confirm(
                this.$t('Уволить сотрудника?'),
                async () => 
                {
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/ems/delemployee?id='+this.dataSource.Employee.employee_id,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        this.$notify.success(response.data.payload.Message);
                        if (response.data.payload.Result === "OK")
                            await this.$router.go(-1);
                    }
                }
            );
            
        },
        async SetSubstantein(){
            try {
                let substantein = await this.$refs.SetSubstanteinDlgRef.open(this.dataSource.Employee.workplace_id, null, this.dataSource.Employee.enterprise_id);
                if (substantein){
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/ems/setsubstantein',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: { Content : JSON.stringify({workplaceid:this.dataSource.Employee.workplace_id, substantenworkplaceid:substantein.param.substanteinid, fromdate:substantein.param.from, todate:substantein.param.to}) },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        await this.getEmployee({ edit: false });
                        this.$notify.success(response.data.payload.Message);
                    }
                }
                
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async CreateLogin(){
            try {
                let user = await this.$refs.CreateUserDlgRef.open(this.isInnerEmployee);
                if (user){
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/security/createuser',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: { Content : JSON.stringify({employee_id:this.dataSource.Employee.employee_id, login:user.param.login, levelAccess:user.param.level.id }) },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        if (response.data.payload.result=="OK"){
                            this.dataSource.Employee.user = {
                                id:response.data.payload.data.formId,
                                login:user.param.login,
                                locked:false,
                                roles:[],
                                rules:[]
                            }
                            await this.getEmployee({ edit: false });
                            await this.$refs.ShowNewPassDlgRef.open(response.data.payload.data.object);
                            this.$notify.success(response.data.payload.message);
                        }
                        else 
                            this.$notify.alert(response.data.payload.message);
                    }
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async ChangeLevelAccess(){
            try {
                let newlevel = await this.$refs.ChangeLevelUserDlgRef.open(this.dataSource.Employee.user.level_access);
                if (newlevel && newlevel!=this.dataSource.Employee.user.level_access){
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/security/changelevelaccessuser',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: { Content : JSON.stringify({userid:this.dataSource.Employee.user.id, level:newlevel }) },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        this.dataSource.Employee.user.level_access = newlevel;
                        this.$notify.success(response.data.payload.Message);
                    }
                    else 
                        this.$notify.alert(response.data.payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async ResetPassword(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let response = await httpAPI({
                url: '/api/security/resetpassword',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content :  this.dataSource.Employee.user.id },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                if (response.data.payload.result=="OK"){
                    await this.$refs.ShowNewPassDlgRef.open(response.data.payload.data.object);
                    this.$notify.success(response.data.payload.message);
                }
                else 
                    this.$notify.alert(response.data.payload.message);
            }
        },
        //#endregion

        //#region Работа с контактами
        async openContactEditDlg(contact) {
            try
            {
                await this.$refs.ContactsRef.open(contact);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onAddContact() {
            await this.openContactEditDlg(null);
        },        
        saveContact(contact) {            
            if (this.dataSource.Employee.person_contacts == null)
                this.dataSource.Employee.person_contacts = [];

            if (contact.isNew) {
                this.dataSource.Employee.person_contacts.push(contact);
            }
            else {
                let existedContact = this.dataSource.Employee.person_contacts.find(x => x.id === contact.id);

                if (existedContact) {
                    existedContact.value = contact.value;
                    existedContact.type = contact.type;
                    existedContact.description = contact.description;
                }
            }
        },
        editContact(contactId) {
            let existedContact = this.dataSource.Employee.person_contacts.find(x => x.id === contactId);

            if (existedContact)
                this.openContactEditDlg(existedContact)
        },
        deleteContact(contactId) {
            let existedContact = this.dataSource.Employee.person_contacts.find(x => x.id === contactId);

            if (existedContact)
                this.dataSource.Employee.person_contacts.splice(this.dataSource.Employee.person_contacts.indexOf(existedContact), 1);
        },
        //#endregion

        //#region Вкладка Права
        async getRuleList(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/getallsecurityrules',
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            
            if (response) {
                this.allrules = response.data.payload.data.object;
            }
        },
        async getRoleList () {
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let response = await httpAPI({
                url: `api/ems/roleslist`,
                method: 'GET',
            });
            this.allroles = response?.data?.payload?.data ?? [];
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        deleteRole(roleId){
            this.dataSource.Employee.user.roles = this.dataSource.Employee.user.roles.filter(r => {return r.id != roleId});
            this.dataSource.Employee.user.rules = this.dataSource.Employee.user.rules.filter(r => {return r.ownerid != roleId});
        },
        async onAddRole(){
            let selectMemberParams =  {
                title: "Выбор_ролей",
                fromSource: true,
                source: this.allroles.map( x =>
                    [ 
                        x.id, //по идее, тут должен быть workplace, но можно использовать и person_id в качестве идентификатора
                        x.name, //формируем для отображение полное имя, формат Фамилия И.О. тут не подходит
                        null, //employeeId нас не интересует
                        null//подробности для персоны, ИИН и дата рождения, т.к. поля присутствуют на форме карточки
                    ]),
                multiple: true,
                selected: this.dataSource.Employee.user.roles.map(r=>r.id)
            }

            try
            {
                let selectedRoles = await this.$store.dispatch('dialogs/selectMembers/open', selectMemberParams);
                console.log(selectedRoles);
                let selectedRolesIds = selectedRoles.map(x => x.workplaceId);
                console.log(selectedRolesIds);
                let userRoles = this.allroles.filter(r=>selectedRolesIds.indexOf(r.id) >= 0);
                console.log(userRoles);
                this.dataSource.Employee.user.roles = userRoles.map(r=>{return {id:r.id,name:r.name}});
                this.dataSource.Employee.user.rules = this.dataSource.Employee.user.rules.filter(r=> !r.inherited);
                userRoles.forEach((role)=>{
                    role.rules.forEach((rule)=>{
                        if (!this.dataSource.Employee.user.rules.find(item=>item.ruleid == rule.ruleid && item.ownerid == role.id))
                            this.dataSource.Employee.user.rules.push({permid:rule.permid, ruleid:rule.ruleid, value:rule.value, ownerid:role.id, inherited:true, inheritedfrom:role.name})
                    });
                });
            }
            catch (ex) {
                console.log(ex);
            }
        },
        //#endregion
    
        //#region Замещающие
        async getSubstantialsList(){
            let { page, itemsPerPage } = this.substantials_filter;

            let newfilter = JSON.stringify(
            { 
                OrderBy: 2,
                OrderDir: true,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            });

            this.substantials_total = 0;
            this.substantials = [];
            this.loading = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            let response = await httpAPI({
                url: `api/ems/substanteinslist?workplaceid=${this.dataSource.Employee.workplace_id}&filter=${newfilter}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            this.substantials = response?.data?.payload?.data ?? [];
            this.substantials_total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        async onInvokeSubstantialAction(action, item){
            await this[action.Action](item);
        },
        async EditSubstantial(item){
            try {
                let substantein = await this.$refs.SetSubstanteinDlgRef.open(this.dataSource.Employee.workplace_id, item, this.dataSource.Employee.enterprise_id);
                if (substantein){
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/ems/editsubstantein',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: { Content : JSON.stringify({substantenid:item.id, substantenworkplaceid:substantein.param.substanteinid, fromdate:substantein.param.from, todate:substantein.param.to}) },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        await this.getEmployee({ edit: false });
                        this.$notify.success(response.data.payload.Message);
                    }
                }
                
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async CancelSubstantial(item){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/cancelsubstantein?id='+item.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                await this.getEmployee({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async DelSubstantial(item){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/delsubstantein?id='+item.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                await this.getEmployee({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        //#endregion
    },
    created() {
        (async() => {
            this.licenseent = await this.getLicenseEnterprise();
            if (this.newinner)
                await this.getNewInnerEmployee();
            else {    
                await this.getRuleList();
                await this.getRoleList();
                await this.getEmployee();
            }
            
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.validate();
        })();      
    },
    updated() {
        if(this.dataSource !== null) {
            this.$refs?.form?.validate();
        }  
    }
}
</script>